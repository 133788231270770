import React from 'react';
import ResumeDownload from './ResumeDownload';

export interface ExperienceProps {}

const Experience: React.FC<ExperienceProps> = (props) => {
    return (
        <div className="site-page-content">
            <ResumeDownload />
            <div style={styles.headerContainer}>
                <div style={styles.header}>
                    <div style={styles.headerRow}>
                        <h1>UW-R</h1>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={'https://www.waterloorocketry.com/'}
                        >
                            <h4>www.waterloorocketry.com</h4>
                        </a>
                    </div>
                    <div style={styles.headerRow}>
                        <h3>Aerospace Designer</h3>
                        <b>
                            <p>January 2024 - Present</p>
                        </b>
                    </div>
                </div>
            </div>
            <div className="text-block">
                <p>
                    Launching a new rocket built from square one on a yearly basis is
                    the Waterloo Rocketry mission. From the gravel of the launch site
                    to the soldering on the internal boards that signal propulsion and
                    recovery system activation, Waterloo Rocketry builds everything from
                    the ground up.
                </p>
                <br />
                <ul>
                    <li>
                        <p>
                            Machining assistance, R&D, and CAD design with the propulsion subteam.
                        </p>
                    </li>
                    <li>
                        <p>
                            Designing marketing content, concept badges, internal material with the media subteam.
                        </p>
                    </li>
                    <li>
                        <p>
                            Researching recovery methods and technologies with the recovery subteam.
                        </p>
                    </li>
                </ul>
            </div>
            <div style={styles.headerContainer}>
                <div style={styles.header}>
                    <div style={styles.headerRow}>
                        <h1>USC</h1>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={'https://www.usccanada.com/'}
                        >
                            <h4>www.usccanada.com</h4>
                        </a>
                    </div>
                    <div style={styles.headerRow}>
                        <h3>Mechanical Designer</h3>
                        <b>
                            <p>May 2024 - January 2024</p>
                        </b>
                    </div>
                </div>
            </div>
            <div className="text-block">
                <p>
                    Engineered novel sustainable waterworks systems and bracketing mechanisms
                    for the Megatite product line, using cost-effective nylon composite bodies
                    in place of traditional connections that cause metallic leaching and are 
                    incapable of withstanding extreme environments.
                </p>
                <br />
                <ul>
                    <li>
                        <p>
                            Created original fixturing for in-house pressure testing chambers, designed O-ring tool.
                        </p>
                    </li>
                    <li>
                        <p>
                            Designed the proposals for the Megatite AMV (118-X3T4) & Service Tee (119-C4X3X3).
                        </p>
                    </li>
                    <li>
                        <p>
                            Rehabilitated $3.5 million property post-flood and built an internal client training centre.
                        </p>
                    </li>
                    <li>
                        <p>
                            Trained new hires while building over $300k worth of novel sustainable water systems.
                        </p>
                    </li>
                    <li>
                        <p>
                            Optimised chemical fusing process time by 32%, increasing output during critical order. 
                        </p>
                    </li>
                    <li>
                        <p>
                            Marketed company and products at conventions and online, increasing reach over 450%.  
                        </p>
                    </li>
                </ul>
            </div>
            <div style={styles.headerContainer}>
                <div style={styles.header}>
                    <div style={styles.headerRow}>
                        <h1>MitoSkin</h1>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={'https://www.mitoskin.com/'}
                        >
                            <h4>www.mitoskin.com</h4>
                        </a>
                    </div>
                    <div style={styles.headerRow}>
                        <h3>Operations Planner</h3>
                        <b>
                            <p>August 2019 - Present</p>
                        </b>
                    </div>
                </div>
            </div>
            <div className="text-block">
                <p>
                    Researched novel industrialization and delivery methods for a novel
                    dermal technology interfacing traditional solar protection with
                    effectively edible ingredients, merging health with sustainability.
                </p>
                <br />
                <ul>
                    <li>
                        <p>
                            Researching novel production methods, real estate, industrial warehousing techniques.
                        </p>
                    </li>
                    <li>
                        <p>
                            Strategizing and networking for sales across the eastern seaboard of the United States.
                        </p>
                    </li>
                    <li>
                        <p>
                            Marketing content creation in addition to researching nutrition, endocrinology.
                        </p>
                        </li>
                </ul>
            </div>
            <div style={styles.headerContainer}>
                <div style={styles.header}>
                    <div style={styles.headerRow}>
                        <h1>uWaterloo</h1>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={'https://www.uwaterloo.ca/'}
                        >
                            <h4>www.uwaterloo.ca</h4>
                        </a>
                    </div>
                    <div style={styles.headerRow}>
                        <h3>Assistant Researcher</h3>
                        <b>
                            <p>May 2021 - September 2021</p>
                        </b>
                    </div>
                </div>
            </div>
            <div className="text-block">
                <p>
                    Researching and stratifying data related to the terrain of food science
                    in contemporary Southern Ontario. 
                </p>
                <br />
                <ul>
                    <li>
                        <p>
                            Analysed over 100k food science samples, parsed 17k academic papers via SQL.
                        </p>
                    </li>
                    <li>
                        <p>
                            Increased efficiency of a categorical sorting process 5x with a single Excel function.
                        </p>
                        </li>
                 </ul>
            </div>
            <div style={styles.headerContainer}>
                <div style={styles.header}>
                    <div style={styles.headerRow}>
                        <h1>Founder Institute</h1>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={'https://www.fi.co/'}
                        >
                            <h4>www.fi.co</h4>
                        </a>
                    </div>
                    <div style={styles.headerRow}>
                        <h3>Intern</h3>
                        <b>
                            <p>March 2019 - July 2019</p>
                        </b>
                    </div>
                </div>
            </div>
            <div className="text-block">
                <p>
                    Launching global startups from the incubation stage into post-funding rounds
                    via ideation, formulation, marketing, legal prepartion, and venture guidance. 
                    Notable cohort companies included an international language learning company,
                    and a novel gene-splicing solution interfacing with augmented analysis.
                </p>
                <br />
                <ul>
                    <li>
                        <p>
                            Launched international startups from pre-seed stages in biotech, language, and sales.
                        </p>
                    </li>
                    <li>
                        <p>
                            Guided cohort to finish achieving “Mentor” status, granted equity in founder ventures.
                        </p>
                    </li>
                </ul>
                 <ResumeDownload />
            </div>
        </div>
    );
};

const styles: StyleSheetCSS = {
    header: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
    },
    skillRow: {
        flex: 1,
        justifyContent: 'space-between',
    },
    skillName: {
        minWidth: 56,
    },
    skill: {
        flex: 1,
        padding: 8,
        alignItems: 'center',
    },
    progressBar: {
        flex: 1,
        background: 'red',
        marginLeft: 8,
        height: 8,
    },
    hoverLogo: {
        height: 32,
        marginBottom: 16,
    },
    headerContainer: {
        alignItems: 'flex-end',
        width: '100%',
        justifyContent: 'center',
    },
    hoverText: {
        marginBottom: 8,
    },
    indent: {
        marginLeft: 24,
    },
    headerRow: {
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

export default Experience;
