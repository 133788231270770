import React, { useState } from 'react';
// @ts-ignore
import houseProject from '../../../assets/pictures/projects/audio/houseProject.png';
import dnbDrumsProject from '../../../assets/pictures/projects/audio/dnbDrumsProject.png';

export interface MusicProjectsProps {}

const MusicProjects: React.FC<MusicProjectsProps> = (props) => {
    const [currentSong, setCurrentSong] = useState<string>('');

    return (
        <div className="site-page-content">
            <h1>Aerospace</h1>
            <h3>Adventures</h3>
            <br />
            <div className="text-block">
                <p>
                    Space has been a passion of mine for my whole life. One of my earliest
                    memories is watching a kite of mine rise into the starry night sky 
                    during an early morning in the mountains - and I have been working to 
                    replicate that feeling ever since.
                </p>
                <br />
                <p>
                    Much of my projects in aerospace lie at the interface between
                </p>
                <br />
                <p>
                    Below are some more recent aero projects I've been
                    working on. I hope you enjoy the visuals and stories
                    behind each of them.
                </p>
            </div>
            <h2></h2>
            <br />
            <p>
                In 2020 I had the opportunity to design 
            </p>
            <br />
            <p>
                I failed a few times as I wasn't really able to nail the style
                and groove I was going for but ultimately ended up creating the
                piece below.
            </p>
            <br />
            <h2>What next?</h2>
            <br />
            <p>
                The stars and space beyond the firmament are unlikely to cease 
            </p>
            <br />
            <p>
                I 
            </p>
            <br />
        </div>
    );
};

// const styles: StyleSheetCSS = {};

export default MusicProjects;
