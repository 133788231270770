import React from 'react';
import me from '../../assets/pictures/workingAtComputer.jpg';
import meNow from '../../assets/pictures/currentme.png';
import { Link } from 'react-router-dom';
import ResumeDownload from './ResumeDownload';

export interface AboutProps {}

const About: React.FC<AboutProps> = (props) => {
    return (
        // add on resize listener
        <div className="site-page-content">
            {/* <img src={me} style={styles.topImage} alt="" /> */}
            <h1 style={{ marginLeft: -16 }}>Welcome</h1>
            <h3>I'm Konrad Borowski</h3>
            <br />
            <div className="text-block">
                <p>
                    I'm a mechanical designer, engineer, and researcher currently 
                    looking for my next role! I am set to graduate in 2025 from
                    the University of Waterloo with my BS in Urban Planning.
                </p>
                <br />
                <p>
                    Thank you for taking the time to check out my resume site. I 
                    hope you enjoy exploring it as much as I enjoyed working on
                    it. If you would prefer to interact with a flat version 
                    of this website, please visit {' '}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://os.konradborowski.com/"
                    >
                        this link
                    </a>
                    . A very large portion of this website was originally designed by{' '}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.linkedin.com/in/henryheffernan/"
                    >
                        Henry Heffernan
                    </a>
                    , whose work you should absolutely take a look at 
                    when time permits. If you have any questions or comments, feel free to contact me 
                    using{' '}
                    <Link to="/contact">this form</Link> or shoot me an email at{' '}
                    <a href="mailto:konrad.borowski@uwaterloo.ca">
                        konrad.borowski@uwaterloo.ca
                    </a>
                    . Be safe and have fun!
                </p>
            </div>
            <ResumeDownload />
            <div className="text-block">
                <h3>About Me</h3>
                <br />
                <p>
                    Since I was a young boy, I have had a deep curiosity about the 
                    inner workings of systems, mechanics, cities, and the world at large. This 
                    fascination naturally led to tearing apart any tools and machines I could get 
                    my hands on, as well as competing on the local LEGO Robotics team. I spent
                    a generous portion of my summers and weekends building digital cities
                    and creating wooden structures in the forest out of anything I could find. 
                </p>
                <br />
                <div className="captioned-image">
                    <img src={me} style={styles.image} alt="" />
                    <p>
                        <sub>
                            <b>Figure 1:</b> A real photo of me learning about centrifugal force :)
                        </sub>
                    </p>
                </div>

                <p>
                    My passion for building things became more serious in high school,
                    where I learned how to build robots and design mechanical systems in tandem with studying
                    physics and high level astronomy. I had the opportunity to compete (and win!) several 
                    mechanical design competitions, going on to do a few passion projects across architecture and engineering.
                </p>
                <br />
                <p>
                    In 2020, I was accepted into the University of Waterloo
                    to study Urban Planning. Choosing between the scale of cities versus buildings,
                    Urban Planning gave me the ability to impact a much larger surface area 
                    in my work while pursuing space. I was and continue to be ecstatic to be attending such
                    a phenomenal university. My education and network have given me 
                    numerous opportunities to work across the sectors of engineering, biotechnology,
                    public research, food systems, and aerospace engineering. As I look towards the future, 
                    my eyes remain in the stars. I hope to one day retire to a quiet farm on the moon.
                </p>
                <br />
                <br />
                <div style={{}}>
                    <div
                        style={{
                            flex: 1,
                            textAlign: 'justify',
                            alignSelf: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <h3>My Hobbies</h3>
                        <br />
                        <p>
                            Beyond my studies and work, I have a lot of hobbies that I
                            enjoy doing in my free time. Some of these include
                            working out, hiking, woodworking, and writing.
                        </p>
                        <br />
                        <p>
                            At Waterloo, I am a member of the Waterloo Rocketry
                            team and currently act as a jack-of-all-trades within the
                            organization. I have met a lot of amazing people through
                            this team and am grateful for the UW community.
                        </p>
                    </div>
                    <div style={styles.verticalImage}>
                        <img src={meNow} style={styles.image} alt="" />
                        <p>
                            <sub>
                                <b>Figure 2:</b> Me, 2023
                            </sub>
                        </p>
                    </div>
                </div>
                <br />
                <br />
                <p>
                    Thanks for reading about me. I hope that you enjoy exploring
                    the rest of my resume website and everything it has to
                    offer. 
                    
                    Good luck and have fun!
                </p>
                <br />
                <p>
                    If you have any questions or comments I would love to hear
                    them. You can reach me through the{' '}
                    <Link to="/contact">contact page</Link> or shoot me an email
                    at{' '}
                    <a href="mailto:konrad.borowski@uwaterloo.ca">
                        konrad.borowski@uwaterloo.ca
                    </a>
                </p>
            </div>
        </div>
    );
};

const styles: StyleSheetCSS = {
    contentHeader: {
        marginBottom: 16,
        fontSize: 48,
    },
    image: {
        height: 'auto',
        width: '100%',
    },
    topImage: {
        height: 'auto',
        width: '100%',
        marginBottom: 32,
    },
    verticalImage: {
        alignSelf: 'center',
        // width: '80%',
        marginLeft: 32,
        flex: 0.8,

        alignItems: 'center',
        // marginBottom: 32,
        textAlign: 'center',
        flexDirection: 'column',
    },
};

export default About;
